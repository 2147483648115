import { generateReportAdmin, getRedemptions } from "apis";
import { updateRedemptionRemarks, voidRedemption } from "apis/redemption.api";
import { Intro } from "components/commons";
import { ConfirmModal, SuccessModal } from "components/modals";
import { SessionContext, UserContext } from "contexts";
import { Portal, ReportType } from "enums";
import { useApi, useModal, useRouter } from "hooks";
import useDataTable from "hooks/useDataTable";
import useExport from "hooks/useExport";
import locale from "localization";
import { useCallback, useContext, useState } from "react";
import { formatVolume } from "utils";
import { columns } from "./redemption-columns";
import RedemptionFilter from "./redemption-filter";
import { initialFilterState } from "./redemption-filter.state";
import RedemptionList from "./redemption-list";
import { mapDataToList, mapFilterToRequest } from "./redemption.mapper";
import VoidTransactionModal from "./void-transaction-modal";

const RedemptionModule = () => {
  const { sessionId } = useContext(SessionContext);
  const { location } = useRouter();
  const voidTransactionModal = useModal();
  const confirmModal = useModal();
  const [remarks, setRemarks] = useState("");
  const [value, setValue] = useState("");
  const { user } = useContext(UserContext);
  const { role } = user?.userAccesses?.find((element) => {
    return element.portal === Portal.LOCQ;
  });

  const { request: voidRequest, loading: voidingRequest } = useApi({
    api: voidRedemption,
    pageError: false,
    modalError: true,
  });

  const updateRemarksApi = useApi({
    api: updateRedemptionRemarks,
    modalError: true,
    pageError: false,
  });

  const onVoidTransactionCb = useCallback(
    async ({ redemptionItem, value }) => {
      await voidRequest({ redemptionId: redemptionItem.redemptionId, remarks: value });
      voidTransactionModal.close();
      table.fetch({});
      confirmModal.show({
        title: locale.transactionVoided,
        content: (
          <locale.Populate
            text={locale.thisTransactionHasBeenVoided}
            items={[
              <b>{formatVolume(redemptionItem?.literVolume)}</b>,
              <b>{redemptionItem?.redemption?.fleet?.businessName}</b>,
            ]}
          />
        ),
        loading: voidingRequest,
        redemptionItem,
      });

      setRemarks("");
    },
    //eslint-disable-next-line
    [voidRequest, voidTransactionModal, voidingRequest, confirmModal]
  );

  const { filter, search, table } = useDataTable({
    api: { api: getRedemptions, params: { platformType: "plb" } },
    filter: { initialState: initialFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "redemptions",
      mapper: (redemptions) =>
        mapDataToList(
          redemptions,
          setRemarks,
          voidTransactionModal,
          onVoidTransactionCb,
          updateRemarksApi,
          setValue, 
          role
        ),
      columns,
    },
    searchKey: location?.state?.searchKey,
  });

  const exports = useExport({
    api: generateReportAdmin,
    hasModal: true,
    reportType: ReportType.Redemption,
    mappedFilterState: {
      searchKey: search.searchKey,
      platformType: "plb",
      sessionId,
      ...filter.mappedFilterState,
    },
  });

  return (
    <div>
      <ConfirmModal {...confirmModal} />
      <SuccessModal {...exports?.successModalComponent} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Intro title={locale.fuelCodeRedemptions} subtitle={locale.viewAllTrackRedemptions} />
      </div>
      <RedemptionFilter {...filter} {...search} {...exports} />
      <RedemptionList table={table} />
      <VoidTransactionModal
        {...voidTransactionModal}
        remarks={remarks}
        onChangeRemarks={(v) => {
          setRemarks(v);
        }}
        loading={voidingRequest || updateRemarksApi.loading}
        onSubmit={voidTransactionModal.submit}
        value={value}
        setValue={setValue}
      />
    </div>
  );
};

export default RedemptionModule;
